import type { FC } from 'react';
import React, { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';

import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import { N0 } from '@atlaskit/theme/colors';
import { WidthObserver } from '@atlaskit/width-detector';

import { GenericErrorBoundary, Attribution } from '@confluence/error-boundary';
import { usePageSpaceKey } from '@confluence/page-context';
import { DocumentSpaceTitle } from '@confluence/document-title';
import { GenericError } from '@confluence/space-pages';
import { ExperienceStart, SPACE_BLOGS_EXPERIENCE } from '@confluence/experience-tracker';

import { SpaceBlogsContextProvider } from './SpaceBlogsContext';
import { SearchResults } from './SearchResults';
import { AppearanceToggle } from './AppearanceToggle';

const i18n = defineMessages({
	blogs: {
		id: 'space-blogs.title',
		defaultMessage: 'Blogs',
		description: "The title for a space's blogs dashboard",
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceBlogsContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	padding: `0px ${token('space.500', '40px')}`,
	margin: '0 auto',
	minWidth: '295px',
	maxWidth: '1320px',
	backgroundColor: token('elevation.surface', N0),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleRow = styled.div({
	display: 'flex',
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	paddingTop: '30px',
});

export const SpaceBlogs: FC = () => {
	const intl = useIntl();
	const [spaceKey] = usePageSpaceKey();
	const [_, setWidth] = useState(0);
	const onResize = debounce(setWidth, 100, { leading: false });

	return (
		<SpaceBlogsContainer>
			<ExperienceStart name={SPACE_BLOGS_EXPERIENCE} />
			<DocumentSpaceTitle titlePrefix={intl.formatMessage(i18n.blogs)} spaceKey={spaceKey} />
			<SpaceBlogsContextProvider>
				<TitleRow id="title-row">
					<Heading size="xlarge" id="title">
						<FormattedMessage {...i18n.blogs} />
					</Heading>
					<AppearanceToggle />
				</TitleRow>

				<GenericErrorBoundary attribution={Attribution.DISCO} renderOnError={GenericError}>
					<SearchResults spaceKey={spaceKey} />
				</GenericErrorBoundary>
			</SpaceBlogsContextProvider>
			<WidthObserver setWidth={onResize} offscreen />
		</SpaceBlogsContainer>
	);
};
