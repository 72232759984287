/* eslint-disable react/jsx-no-constructed-context-values */
import type { FC } from 'react';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { getApolloClient } from '@confluence/graphql';
import { getSingleParam, RoutesContext } from '@confluence/route-manager';
import type { PageCardAppearance, SpacePageCard } from '@confluence/page-card';
import { useSessionData } from '@confluence/session-data';
import { usePageSpaceKey } from '@confluence/page-context';

import {
	SpaceBlogsAppearancePersistenceQuery,
	SpaceBlogsAppearanceMutation,
} from './SpaceBlogsPersistenceQueries.graphql';
import type {
	SpaceBlogsAppearanceMutationVariables,
	SpaceBlogsAppearanceMutation as SpaceBlogsAppearanceMutationType,
} from './__types__/SpaceBlogsAppearanceMutation';
import { PagesDisplayPersistenceOption } from './__types__/SpaceBlogsAppearanceMutation';
import type { SpaceBlogsAppearancePersistenceQuery as SpaceBlogsAppearancePersistenceQueryType } from './__types__/SpaceBlogsAppearancePersistenceQuery';

const QueryParamKeys = {
	APPEARANCE: 'view',
};

export const BLOG_STATUSES = ['current', 'draft'];
export type SpaceBlogsYearSearchResults = {
	id: number;
	children: SpacePageCard[];
	hasAllChildren: boolean;
};

type SetAppearance = (appearance: PageCardAppearance) => void;
type SetSelectedYearPill = (filter: string) => void;
type SetTotalCount = (count: number) => void;
type SetSearchResults = (results: SpaceBlogsYearSearchResults[]) => void;
export const BlogsAllPillFilter = 'All';

type SpaceBlogsContextType = {
	appearance: PageCardAppearance;
	setAppearance: SetAppearance;
	persistenceQueryError?: Object;
	persistenceQueryLoading: boolean;
	selectedYearPill: string;
	setSelectedYearPill: SetSelectedYearPill;
	totalCount: number;
	setTotalCount: SetTotalCount;
	searchResults: SpaceBlogsYearSearchResults[];
	setSearchResults: SetSearchResults;
};

const defaultSpaceBlogsContext: SpaceBlogsContextType = {
	appearance: 'grid',
	setAppearance: () => {},
	persistenceQueryError: undefined,
	persistenceQueryLoading: false,
	selectedYearPill: BlogsAllPillFilter,
	setSelectedYearPill: () => {},
	totalCount: 0,
	setTotalCount: () => {},
	searchResults: [],
	setSearchResults: () => {},
};

const getAppearance = (appearance: string | null): PageCardAppearance => {
	return appearance === PagesDisplayPersistenceOption.LIST
		? 'list'
		: appearance === PagesDisplayPersistenceOption.COMPACT_LIST
			? 'compact-list'
			: 'grid';
};

const convertPageCardAppearanceToPersistenceOption = (appearance: PageCardAppearance) => {
	if (appearance === 'list') {
		return PagesDisplayPersistenceOption.LIST;
	}
	if (appearance === 'grid') {
		return PagesDisplayPersistenceOption.CARDS;
	}
	if (appearance === 'compact-list') {
		return PagesDisplayPersistenceOption.COMPACT_LIST;
	}
};

export const SpaceBlogsContext = createContext<SpaceBlogsContextType>(defaultSpaceBlogsContext);

/*
 * SpacePagesContextProvider contains the logic for accessing and updating
 * filters and other user options. The state is stored within query params
 * however, updating query params causes most of the app to rerender and leads
 * to noticeable lag in the UI. Because of this, the component reads the query
 * params initially and stores them into local state which is then passed into
 * the context provider. On every update, first the state is updated to avoid
 * any lag, then, once that is complete, the query params are updated. This
 * is achieved using setTimeout(() => {...}, 0).
 *
 * This solution is a hack and should be cleaned up in the future.
 */
export const SpaceBlogsContextProvider: FC<{ children?: React.ReactNode }> = ({ children }) => {
	const { getQueryParams, setQueryParams } = useContext(RoutesContext);
	const [stateSpaceKey] = usePageSpaceKey();
	// @ts-ignore FIXME: `stateSpaceKey` can be `undefined` here, and needs proper handling
	const spaceKey: string = stateSpaceKey;
	const queryParams = getQueryParams();
	const { isLoggedIn } = useSessionData();

	const {
		data: persistenceQueryData,
		loading: persistenceQueryLoading,
		error: persistenceQueryError,
	} = useQuery<SpaceBlogsAppearancePersistenceQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceBlogsAppearancePersistenceQuery,
		{
			skip: !isLoggedIn,
		},
	);
	const [selectedYearPill, setSelectedYearPill] = useState(BlogsAllPillFilter);
	const [totalCount, setTotalCount] = useState(0);
	const [searchResults, setSearchResults] = useState<SpaceBlogsYearSearchResults[]>([]);
	const [appearanceState, setAppearanceState] = useState<PageCardAppearance>(() =>
		getAppearance(getSingleParam(queryParams, QueryParamKeys.APPEARANCE) || null),
	);

	useEffect(() => {
		if (persistenceQueryData) {
			setAppearanceState(
				getAppearance(
					persistenceQueryData?.userPreferences?.globalPageCardAppearancePreference || null,
				),
			);
		}
	}, [persistenceQueryData]);

	const [updatePersistedAppearance] = useMutation<
		SpaceBlogsAppearanceMutationType,
		SpaceBlogsAppearanceMutationVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		SpaceBlogsAppearanceMutation,
	);

	const setAppearanceForAnonymousUsers: SetAppearance = useCallback(
		(appearance) => {
			setAppearanceState(appearance);
			setTimeout(() => {
				setQueryParams({ [QueryParamKeys.APPEARANCE]: appearance }, true);
			}, 0);
		},
		[setQueryParams],
	);

	const setAppearance: SetAppearance = useCallback(
		(appearance: PageCardAppearance) => {
			const appearanceOption = convertPageCardAppearanceToPersistenceOption(appearance);

			updatePersistedAppearance({
				variables: {
					persistenceOption: appearanceOption || PagesDisplayPersistenceOption.CARDS,
				},
			}).catch(() => {}); // ignoring errors here because we are consuming error from useMutation

			// Update the cache to keep UI updated regardless of whether the mutation succeeds
			getApolloClient().writeQuery({
				query: SpaceBlogsAppearancePersistenceQuery,
				data: {
					userPreferences: {
						globalPageCardAppearancePreference: appearanceOption,
						__typename: 'UserPreferences',
					},
				},
			});
		},
		[updatePersistedAppearance],
	);

	const spaceBlogsContextValue = useMemo(
		() => ({
			appearance: appearanceState,
			setAppearance: isLoggedIn ? setAppearance : setAppearanceForAnonymousUsers,
			selectedYearPill,
			setSelectedYearPill,
			persistenceQueryError,
			persistenceQueryLoading,
			setTotalCount,
			totalCount,
			searchResults,
			setSearchResults,
		}),
		[
			appearanceState,
			isLoggedIn,
			persistenceQueryError,
			persistenceQueryLoading,
			searchResults,
			selectedYearPill,
			setAppearance,
			setAppearanceForAnonymousUsers,
			totalCount,
		],
	);

	return (
		<SpaceBlogsContext.Provider value={spaceBlogsContextValue}>
			{children}
		</SpaceBlogsContext.Provider>
	);
};
